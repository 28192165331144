/* stylelint-disable selector-max-specificity */
/* stylelint-disable selector-type-no-unknown */

/* -------------------------------- 
 *           Global Styles         
 *--------------------------------- */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

table {
  /* stylelint-disable-next-line primer/borders */
  border-collapse: collapse;
}

[role='button']:focus:not(:focus-visible):not(.focus-visible),
[role='tabpanel'][tabindex='0']:focus:not(:focus-visible):not(.focus-visible),
button:focus:not(:focus-visible):not(.focus-visible),
summary:focus:not(:focus-visible):not(.focus-visible),
a:focus:not(:focus-visible):not(.focus-visible) {
  outline: none;
  box-shadow: none;
}

[tabindex='0']:focus:not(:focus-visible):not(.focus-visible),
details-dialog:focus:not(:focus-visible):not(.focus-visible) {
  outline: none;
}

/* -------------------------------------------------------------------------- */

.BaseStyles {
  /* Global styles for light mode */
  &:has([data-color-mode='light']) {
    input & {
      color-scheme: light;
    }
  }

  /* Global styles for dark mode */
  &:has([data-color-mode='dark']) {
    input & {
      color-scheme: dark;
    }
  }
}
