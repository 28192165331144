/* stylelint-disable selector-max-specificity, selector-max-compound-selectors */

.ActionList {
  padding: 0;
  margin: 0;
  list-style: none;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &:where([data-variant='inset']) {
    /* change to padding (all) when Item is converted */
    padding-block: var(--base-size-8);
  }

  &:where([data-dividers='true']) {
    /* place dividers on the wrapper that excludes leading visuals/actions */
    & .ActionListSubContent::before {
      position: absolute;
      /* stylelint-disable-next-line primer/spacing */
      top: calc(-1 * var(--control-medium-paddingBlock));
      display: block;
      width: 100%;
      height: 1px;
      content: '';
      /* stylelint-disable-next-line primer/colors */
      background: var(--borderColor-muted);
    }

    /* if inline description, move pseudo divider to description wrapper */
    & [data-description-variant='inline'] {
      &::before {
        position: absolute;
        /* stylelint-disable-next-line primer/spacing */
        top: calc(-1 * var(--control-medium-paddingBlock));
        display: block;
        width: 100%;
        height: var(--borderWidth-thin);
        content: '';
        /* stylelint-disable-next-line primer/colors */
        background: var(--borderColor-muted);
      }

      /* remove the default divider */
      & .ActionListSubContent::before {
        content: unset;
      }
    }

    /* hide if item is first of type with label::before, or is the first item after a sectionDivider */
    .ActionListItem:first-of-type .ActionListSubContent::before,
    .Divider + .ActionListItem .ActionListSubContent::before {
      visibility: hidden;
    }

    /* hide if item is first of type with label::before, or is the first item after a sectionDivider */
    .ActionListItem:first-of-type [data-description-variant='inline']::before,
    .Divider + .ActionListItem [data-description-variant='inline']::before {
      visibility: hidden;
    }
  }
}

.Divider {
  display: block;
  height: var(--borderWidth-thin);
  padding: 0;
  /* stylelint-disable-next-line primer/spacing */
  margin-block-start: calc(var(--base-size-8) - var(--borderWidth-thin));
  margin-block-end: var(--base-size-8);
  margin-inline: calc(-1 * var(--base-size-8));
  list-style: none;
  /* stylelint-disable-next-line primer/colors */
  background: var(--borderColor-muted);
  border: 0;
}
